import React from "react";
import Header from "../components/Header";
import Bottom from "../components/Bottom";
import queryString from "query-string";
import jwt from "jsonwebtoken";
import locale2 from "locale2";
import LocalizedStrings from 'react-localization';
import cleanObject from "../helpers/object-cleaner";

import _ from "lodash";

import { preferencesGetRequest, lookupValuesRequest, savePreferences } from '../requests';

import AWS from 'aws-sdk';
const fromDynamoConverter = AWS.DynamoDB.Converter.unmarshall;
const toDynamoConverter = AWS.DynamoDB.Converter.marshall;

const localizationStrings = new LocalizedStrings({
  en: {
    message: "Subscription Preferences Saved!"
  },
  ca: {
    message: "Subscription Preferences Saved!"
  },
  gb: {
    message: "Subscription Preferences Saved!"
  }
});

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: props._config,
      preferencesObject: null,
      displayToast: false,
      unsubscribeFromLease: false,
      unsubscribeFromSale: false,
      unsubscribeFromAll: false,
      showMarketModal: false,
      saveButtonEnabled: false,
      showErrorPage: false,
      preventFlicker: true,
      type: 'lease',
      propertyTypeArray: [],
      oldPropertyTypeArray: [],
      propertyTypeOptionsArray: [],
      spaceUseArray: [],
      oldSpaceUseArray: [],
      spaceUseOptionsArray: [],
      forLeaseMarketSelections: [],
      oldForLeaseMarketSelections: [],
      forSaleMarketSelections: [],
      oldForSaleMarketSelections: [],
      availableMarketList: [],
      showRegionSelect: true,
      isLoading: true,
      region: 'US',
      salePriceRangePreference: {},
      includeUndisclosedSalePrice: true,
      spaceRange: null,
      forLeaseStarRating:[],
      forSaleStarRating: []
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleMarketClick = this.handleMarketClick.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.updateSelectedMarkets = this.updateSelectedMarkets.bind(this);
    this.updateSpaceUseArray = this.updateSpaceUseArray.bind(this);
    this.updatePropertyTypeArray = this.updatePropertyTypeArray.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formatPreferences = this.formatPreferences.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleRegionSelectionChange = this.handleRegionSelectionChange.bind(this);
    this.handleSalePricePreferenceChange = this.handleSalePricePreferenceChange.bind(this);
    this.handleUndisclosedCheckbox = this.handleUndisclosedCheckbox.bind(this);
    this.updateSpaceRange = this.updateSpaceRange.bind(this)
    this.updateStarRating = this.updateStarRating.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setBottomScrollHeight);
    this.setBottomScrollHeight();

    if (this.isMobileDevice()) {
      const el = document.querySelector('.leftside-component');
      el.className += ' mobile-device';
    }

    const routingParams = queryString.parse(this.props.location.search);
    const decodedJWT = jwt.decode(routingParams.t);

    // Handle the cases where the JWT is invalid
    if (decodedJWT === null || typeof routingParams.t === 'undefined' || typeof decodedJWT.email === 'undefined') {
      this.showErrorPage();
      return;
    }

    // Resolve proper listing type
    let listingType = typeof decodedJWT.type !== 'undefined' ? decodedJWT.type : 'lease';

    if (typeof listingType === 'number') {
      listingType = listingType === 1 ? 'sale' : 'lease';
    } else if (listingType !== 'sale' && listingType !== 'lease') {
      listingType = 'lease';
    }

    preferencesGetRequest(this.state.config, decodedJWT.email)
      .then(value => {

        let preferencesObj = fromDynamoConverter(value.Item);

        if (!preferencesObj.contactId && decodedJWT.contactId) {
          preferencesObj.contactId = parseInt(decodedJWT.contactId, 10);
        } else {
          preferencesObj.contactId = null;
        }

        if (!preferencesObj.countryCode && decodedJWT.countryCode) {
          preferencesObj.countryCode = decodedJWT.countryCode;
        }

        // If there is a country code being returned from the backend or in the JWT token
        if (preferencesObj.countryCode) {
          let region;
          switch (preferencesObj.countryCode) {
            case 'CAN':
              region = 'CA';
              break;
            case 'GBR':
              region = 'GB';
              break;
            case 'USA':
            default:
              region = 'US';
          }

          this.handleNetworkCall(preferencesObj, region, decodedJWT.email, listingType, false);
        } else {
          this.setState({
            emailAddress: decodedJWT.email,
            showRegionSelect: true,
            preventFlicker: false,
            isLoading: false,
            type: listingType,
            preferencesObject: preferencesObj,
            includeUndisclosedSalePrice: true
          });
        }
      }).catch(error => {
        this.showErrorPage();
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setBottomScrollHeight);
  }

  handleNetworkCall(preferencesObj, region, emailAddress, listingType, clearPreferences) {
    const fullLocale = locale2 === 'en' ? 'en-US' : locale2;

    lookupValuesRequest(this.state.config, region, fullLocale)
      .then(lookupValues => {
        // If the backend does not return a valid response for the email in the jwt
        if (typeof preferencesObj === 'undefined' || typeof preferencesObj.emailAddress === 'undefined') {
          preferencesObj = {
            emailAddress,
            forLease: null,
            forSale: null,
            isDefault: true,
            contactId: preferencesObj.contactId || null,
            countryCode: region
          };
        }

        let forLeaseMarketSelections = [];
        let forSaleMarketSelections = [];
        let forLeaseStarRating = [];
        let forSaleStarRating = [];
        let unsubscribeFromLease = false;
        let unsubscribeFromSale = false;
        let salePriceRangePreference = {};
        let includeUndisclosedSalePrice = true;

        let spaceUseArray = [];
        let propertyTypeArray = [];
        let spaceRange = null;

        if (!clearPreferences) {
          if (preferencesObj.forLease) {
            unsubscribeFromLease = preferencesObj.forLease.unsubscribeAll ? preferencesObj.forLease.unsubscribeAll : false;
            forLeaseMarketSelections = preferencesObj.forLease.markets ? preferencesObj.forLease.markets : [];
            spaceUseArray = preferencesObj.forLease.spaceUses ? preferencesObj.forLease.spaceUses : [];
            spaceRange = preferencesObj.forLease.spaceRange ? preferencesObj.forLease.spaceRange : null;
            forLeaseStarRating = preferencesObj.forLease.starRating ? preferencesObj.forLease.starRating : [];
          }

          if (preferencesObj.forSale) {
            unsubscribeFromSale = preferencesObj.forSale.unsubscribeAll ? preferencesObj.forSale.unsubscribeAll : false;
            forSaleMarketSelections = preferencesObj.forSale.markets ? preferencesObj.forSale.markets : [];
            propertyTypeArray = preferencesObj.forSale.propertyTypes ? preferencesObj.forSale.propertyTypes : [];
            salePriceRangePreference = preferencesObj.forSale.priceRange ? preferencesObj.forSale.priceRange : {};
            forSaleStarRating = preferencesObj.forSale.starRating ? preferencesObj.forSale.starRating : [];
            includeUndisclosedSalePrice = preferencesObj.forSale.priceRange && preferencesObj.forSale.priceRange.hasOwnProperty("includeUndisclosed") ? preferencesObj.forSale.priceRange.includeUndisclosed : true
          }
        }
        // Format propertyType data
        let propertyTypeOptionsArray = lookupValues.PropertyTypes.Items.map(val => {
          return { value: val.Id, label: val.Name }
        });

        if (region === 'GB') {
          propertyTypeOptionsArray = propertyTypeOptionsArray.filter(pType => {
            return pType.value !== 11;
          });
        }

        let formattedPropertyTypeArray = [];
        _(propertyTypeArray).forEach(function (val) {
          const foundObj = _.find(propertyTypeOptionsArray, function (obj) {
            return obj.value === val;
          });
          formattedPropertyTypeArray.push(foundObj);
        });

        // Break office/medical and office/retail into independent fields
        let addSpaceUseValues = [];
        _(spaceUseArray).forEach(function (spaceUseId) {
          if (spaceUseId === 2) {
            addSpaceUseValues.push(...[1, 4]);
          } else if (spaceUseId === 3) {
            addSpaceUseValues.push(...[1, 5]);
          }
        });

        // Remove 2 and 3
        spaceUseArray = spaceUseArray.filter(function (spaceUseVal) {
          return spaceUseVal !== 2 && spaceUseVal !== 3;
        });

        spaceUseArray.push(...addSpaceUseValues);

        // Remove duplicates
        spaceUseArray = spaceUseArray.filter(function (spaceUse, index, self) {
          return index === self.indexOf(spaceUse);
        });

        this.setState({
          forLeaseMarketSelections,
          oldForLeaseMarketSelections: forLeaseMarketSelections.slice(),
          forSaleMarketSelections,
          oldForSaleMarketSelections: forSaleMarketSelections.slice(),
          availableMarketList: lookupValues.ResearchMarkets.Items,
          region,
          spaceUseOptionsArray: lookupValues.SpaceUses.Items,
          spaceUseArray,
          oldSpaceUseArray: spaceUseArray.slice(),
          propertyTypeOptionsArray,
          propertyTypeArray: formattedPropertyTypeArray,
          oldPropertyTypeArray: formattedPropertyTypeArray.slice(),
          type: listingType,
          unsubscribeFromLease,
          unsubscribeFromSale,
          preventFlicker: false,
          salePriceRangePreference: salePriceRangePreference,
          includeUndisclosedSalePrice: includeUndisclosedSalePrice,
          preferencesObject: preferencesObj,
          showRegionSelect: false,
          isLoading: false,
          spaceRange: spaceRange,
          forSaleStarRating,
          forLeaseStarRating
        }, () => {
          this.handleCheckboxChange('', true);
        });
      }).catch(error => {
        this.showErrorPage();
      });
  }

  isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  setBottomScrollHeight() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const headerSpacingEl = document.querySelector('.header-spacing');
    const bottomComponentEl = document.querySelector('.bottom-component');

    if (bottomComponentEl !== null) {
      if (windowWidth <= 660) {
        headerSpacingEl.style.top = (windowHeight - 70) + 'px';
        bottomComponentEl.style.height = (windowHeight - 109) + 'px';
      } else {
        headerSpacingEl.style.top = '0px';
        bottomComponentEl.style.height = '798px';
      }
    }
  }

  showErrorPage() {
    this.setState({
      showErrorPage: true,
      preventFlicker: false
    });
  }

  formatPreferences(preferences) {
    const dynamoValues = preferences.map(preference => {
      return preference.value;
    })
    return dynamoValues;
  }
  handleSalePricePreferenceChange(updatedOption) {
    this.setState({
      salePriceRangePreference: {
        min: updatedOption.minValue,
        max: updatedOption.maxValue
      }
    }, () => {
      this.checkEnableSave()
    })
  }

  handleSave() {
    //create array of space use values 
    let spaceUses = typeof this.state.spaceUseArray[0] === "object" ? this.formatPreferences(this.state.spaceUseArray) : this.state.spaceUseArray;
    let propertyTypes = typeof this.state.propertyTypeArray[0] === "object" ? this.formatPreferences(this.state.propertyTypeArray) : this.state.propertyTypeArray;
    let forLeaseMarkets = typeof this.state.forLeaseMarketSelections[0] === "object" ? this.formatPreferences(this.state.forLeaseMarketSelections) : this.state.forLeaseMarketSelections;
    let forSaleMarkets = typeof this.state.forSaleMarketSelections[0] === "object" ? this.formatPreferences(this.state.forSaleMarketSelections) : this.state.forSaleMarketSelections;
    let spaceRange = cleanObject(this.state.spaceRange);
    let salePriceRange = cleanObject(this.state.salePriceRangePreference)

    // If for lease data is not completely filled in, do not save it
    let revertForLease = false;
    if (forLeaseMarkets.length === 0 || spaceUses.length === 0) {
      forLeaseMarkets = this.state.oldForLeaseMarketSelections;
      spaceUses = typeof this.state.oldSpaceUseArray[0] === "object" ? this.formatPreferences(this.state.oldSpaceUseArray) : this.state.oldSpaceUseArray;
      revertForLease = true;
    }

    // If for sale data is not completely filled in, do not save it
    let revertForSale = false;
    if (forSaleMarkets.length === 0 || propertyTypes.length === 0) {
      forSaleMarkets = this.state.oldForSaleMarketSelections;
      propertyTypes = typeof this.state.oldPropertyTypeArray[0] === "object" ? this.formatPreferences(this.state.oldPropertyTypeArray) : this.state.oldPropertyTypeArray;
      revertForSale = true;
    }

    let countryCode;
    switch (this.state.region) {
      case 'CA':
        countryCode = 'CAN';
        break;
      case 'GB':
        countryCode = 'GBR';
        break;
      case 'US':
      default:
        countryCode = 'USA';
    }
    const priceRangePreferences = Object.assign({}, salePriceRange, {
      includeUndisclosed: this.state.includeUndisclosedSalePrice
    })

    const forLeasePreferences = {
      markets: forLeaseMarkets,
      spaceUses,
      unsubscribeAll: this.state.unsubscribeFromLease,
      starRating: this.state.forLeaseStarRating
    }
    if (spaceRange && Object.keys(spaceRange).length > 0) {
      forLeasePreferences.spaceRange = spaceRange
    }
    
    const dynamoUpdate = Object.assign({}, this.state.preferencesObject, {
      forLease: forLeasePreferences,
      isDefault: false,
      forSale: {
        markets: forSaleMarkets,
        propertyTypes,
        unsubscribeAll: this.state.unsubscribeFromSale,
        priceRange: priceRangePreferences,
        starRating: this.state.forSaleStarRating
      },
      countryCode
    });

    const jsonToDynamo = toDynamoConverter(dynamoUpdate);
    const dynamoRequest = {
      TableName: `CTX.Subscriptions-${this.state.config.environment}`,
      Item: jsonToDynamo
    };

    Promise.resolve(savePreferences(this.state.config, dynamoUpdate.emailAddress, dynamoRequest))
      .then(() => {
        this.setState({
          displayToast: true,
          spaceUseArray: revertForLease ? this.state.oldSpaceUseArray.slice() : this.state.spaceUseArray,
          oldSpaceUseArray: revertForLease ? this.state.oldSpaceUseArray : this.state.spaceUseArray.slice(),
          forLeaseMarketSelections: revertForLease ? this.state.oldForLeaseMarketSelections.slice() : this.state.forLeaseMarketSelections,
          oldForLeaseMarketSelections: revertForLease ? this.state.oldForLeaseMarketSelections : this.state.forLeaseMarketSelections.slice(),
          propertyTypeArray: revertForSale ? this.state.oldPropertyTypeArray.slice() : this.state.propertyTypeArray,
          oldPropertyTypeArray: revertForSale ? this.state.oldPropertyTypeArray : this.state.propertyTypeArray.slice(),
          forSaleMarketSelections: revertForSale ? this.state.oldForSaleMarketSelections.slice() : this.state.forSaleMarketSelections,
          oldForSaleMarketSelections: revertForSale ? this.state.oldForSaleMarketSelections : this.state.forSaleMarketSelections.slice(),
          spaceRange: this.state.spaceRange
        });
      })
      .catch(error => {
        this.showErrorPage();
      });
  }

  //Whitney: refactor this to use Object.assign
  handleUndisclosedCheckbox() {
    this.setState({
      includeUndisclosedSalePrice: !this.state.includeUndisclosedSalePrice
    })
  }

  isSalePriceValid() {
    return (!this.state.salePriceRangePreference.min && !this.state.salePriceRangePreference.max) || this.state.salePriceRangePreference.min && this.state.salePriceRangePreference.max && this.state.salePriceRangePreference.min <= this.state.salePriceRangePreference.max
  }

  handleCheckboxChange(checkboxName, firstCall) {
    let unsubscribeFromLease =
      checkboxName === "unsubscribeFromLease"
        ? !this.state.unsubscribeFromLease
        : this.state.unsubscribeFromLease;
    let unsubscribeFromSale =
      checkboxName === "unsubscribeFromSale"
        ? !this.state.unsubscribeFromSale
        : this.state.unsubscribeFromSale;
    let unsubscribeFromAll =
      checkboxName === "unsubscribeFromAll"
        ? !this.state.unsubscribeFromAll
        : this.state.unsubscribeFromAll;

    if (!firstCall) {
      if (checkboxName === "unsubscribeFromLease" || checkboxName === "unsubscribeFromSale") {
        if (unsubscribeFromLease && unsubscribeFromSale) {
          unsubscribeFromAll = true;
        } else {
          unsubscribeFromAll = false;
        }
      } else if (unsubscribeFromAll) {
        unsubscribeFromLease = true;
        unsubscribeFromSale = true;
      } else {
        unsubscribeFromLease = false;
        unsubscribeFromSale = false;
      }
    } else if (unsubscribeFromLease && unsubscribeFromSale) {
      unsubscribeFromAll = true;
    }

    this.setState({
      unsubscribeFromLease,
      unsubscribeFromSale,
      unsubscribeFromAll
    }, () => {
      this.checkEnableSave();
    });
  }


  validateRange(rangeObject) {
    if (rangeObject) {
      if (rangeObject.min && rangeObject.max) {
        return (Number.isInteger(rangeObject.min) && Number.isInteger(rangeObject.max) && rangeObject.min <= rangeObject.max)
      }
      return true
    } else {
      return true
    }
  }

  checkEnableSave() {
    let saveButtonEnabled = false;
    if (this.state.unsubscribeFromLease ||
      (this.state.spaceUseArray.length && this.state.forLeaseMarketSelections.length) ||
      (this.state.propertyTypeArray.length && this.state.forSaleMarketSelections.length)) {
      if (this.validateRange(this.state.spaceRange) && this.validateRange(this.state.salePriceRangePreference)) {
        saveButtonEnabled = true;
      }
    }
    this.setState({
      saveButtonEnabled
    });
  }

  handleMarketClick() {
    this.setState({
      showMarketModal: true
    });
  }

  handleTypeChange(type) {
    this.setState({
      type,
      showMarketModal: false //Also close model when changing tabs
    });
  }

  handleRegionSelectionChange(region) {
    this.setState({
      isLoading: true
    }, () => {
      this.handleNetworkCall(this.state.preferencesObject, region.value, this.state.emailAddress, this.state.type, true);
    });
  }

  onDismiss() {
    this.setState({
      showMarketModal: false
    });
  }

  onSelected(selectedMarket) {
    if (this.state.type === 'lease') {
      this.setState({
        showMarketModal: false,
        forLeaseMarketSelections: selectedMarket.slice()
      }, () => {
        this.checkEnableSave();
      });
    } else {
      this.setState({
        showMarketModal: false,
        forSaleMarketSelections: selectedMarket.slice()
      }, () => {
        this.checkEnableSave();
      });
    }
  }

  updateSelectedMarkets(selectedMarkets) {
    if (this.state.type === 'lease') {
      this.setState({
        forLeaseMarketSelections: selectedMarkets.slice()
      }, () => {
        this.checkEnableSave();
      });
    } else {
      this.setState({
        forSaleMarketSelections: selectedMarkets.slice()
      }, () => {
        this.checkEnableSave();
      });
    }
  }

  updateSpaceUseArray(spaceUseArray) {
    this.setState({
      spaceUseArray: spaceUseArray.slice()
    }, () => {
      this.checkEnableSave();
    })
  }

  updatePropertyTypeArray(propertyTypeArray) {
    this.setState({
      propertyTypeArray
    }, () => {
      this.checkEnableSave();
    });
  }

  updateSpaceRange(spaceRange) {
    this.setState({
      spaceRange
    }, () => {
      this.checkEnableSave();
    })
  }

  updateStarRating(starRating){
    if (this.state.type === 'lease') {
      this.setState({
        forLeaseStarRating: starRating.slice()
      }, () => {
        this.checkEnableSave();
      });
    } else {
      this.setState({
        forSaleStarRating: starRating.slice()
      }, () => {
        this.checkEnableSave();
      });
    }
  }

  render() {
    // Clone arrays
    const forLeaseMarketSelections = this.state.forLeaseMarketSelections.slice();
    const forSaleMarketSelections = this.state.forSaleMarketSelections.slice();
    const spaceUseArray = this.state.spaceUseArray.slice();
    const availableMarketList = this.state.availableMarketList.slice();
    const spaceUseOptionsArray = this.state.spaceUseOptionsArray.slice();

    let displayContents;

    if (!this.state.showErrorPage) {
      displayContents = (
        <div className="no-flickering">
          <Header />
          <Bottom
            config={this.state.config}
            preferencesObject={this.state.preferencesObject}
            unsubscribeFromLease={this.state.unsubscribeFromLease}
            unsubscribeFromSale={this.state.unsubscribeFromSale}
            unsubscribeFromAll={this.state.unsubscribeFromAll}
            handleCheckboxChange={this.handleCheckboxChange}
            showMarketModal={this.state.showMarketModal}
            handleMarketClick={this.handleMarketClick}
            onDismiss={this.onDismiss}
            onSelected={this.onSelected}
            forLeaseMarketSelections={forLeaseMarketSelections}
            forSaleMarketSelections={forSaleMarketSelections}
            updateSelectedMarkets={this.updateSelectedMarkets}
            updateSpaceUseArray={this.updateSpaceUseArray}
            propertyTypeOptionsArray={this.state.propertyTypeOptionsArray}
            propertyTypeArray={this.state.propertyTypeArray}
            updatePropertyTypeArray={this.updatePropertyTypeArray}
            spaceUseArray={spaceUseArray}
            spaceUseOptionsArray={spaceUseOptionsArray}
            type={this.state.type}
            handleTypeChange={this.handleTypeChange}
            availableMarketList={availableMarketList}
            handleRegionSelectionChange={this.handleRegionSelectionChange}
            showRegionSelect={this.state.showRegionSelect}
            handleSalePricePreferenceChange={this.handleSalePricePreferenceChange}
            salePricePreference={this.state.salePriceRangePreference}
            includeUndisclosedSalePrice={this.state.includeUndisclosedSalePrice}
            handleUndisclosedCheckbox={this.handleUndisclosedCheckbox}
            updateSpaceRange={this.updateSpaceRange}
            spaceRange={this.state.spaceRange}
            onSave={this.handleSave}
            saveButtonEnabled={this.state.saveButtonEnabled}
            updateStarRating={this.updateStarRating}
            starRating={this.state.type === 'lease' ? this.state.forLeaseStarRating : this.state.forSaleStarRating}
          />
        </div>
      );
    } else {
      displayContents = (
        <div className="display-error">Unable to display page</div>
      );
    }

    let mainClass = 'main-component';

    if (this.state.preventFlicker) {
      mainClass += ' no-flicker';
    }

    let displayToast = '';
    if (this.state.displayToast) {
      displayToast = (
        <div>
          <div className="toast-bg">
            <div className="toast">
              <div className="toast-body">
                <div className="toast-icon"></div>
                <div className="toast-text">{localizationStrings.message}</div>
              </div>
            </div>
          </div>
        </div>
      );

      setTimeout(() => {
        this.setState({
          displayToast: false
        });
      }, 3000);
    }

    let loading = '';
    if (this.state.isLoading) {
      loading = (
        <div>
          <div className="toast-bg">
            <div className="spinner"></div>
          </div>
        </div>
      );
    }

    return (
      <div className={mainClass}>
        {loading}
        {displayToast}
        {displayContents}
      </div>
    );
  }
}

export default Main;