import React, { Component } from 'react';
import MapApi, { Data, DataStyle, FitToBounds, GeoJson, Map, getBoundsByFeatures } from '@costar/react-google-map';
import 'whatwg-fetch';
import _ from 'lodash';
import USMarketsPolys from '../marketsPolylines/USMarketsPolyData';
import CAMarketsPolys from '../marketsPolylines/CAMarketsPolyData';
import UKMarketsPolys from '../marketsPolylines/UKMarketsPolyData';

window.google = window.google ? window.google : {}

const geoJsonStyle = {
	fillColor: '#3498db',
	strokeColor: '#0559B3',
	strokeWeight: 3,
	fillOpacity: 0.6
}

const LayerModes = {
	detailed: 'd',
	reduced: 'r'
}

const geoJsonOptions = {
	idPropertyName: 'geographyId'
}

let usFitBounds = { northeast: { lat: 49.38, lng: -66.94 }, southwest: { lat: 25.82, lng: -124.39 } }
let ukFitBounds = { northeast: { lat: 61.5471111, lng: 9.5844157 }, southwest: { lat: 47.5554486, lng: -18.5319589 } }
let caFitBounds = { northeast: { lat: 70, lng: -50 }, southwest: { lat: 42, lng: -142 } }

function fetchAll(urls) {
	return Promise.all(
		urls.map(url => fetch(url)
			.then(r => r.json())
			.then(data => ({ data, url }))
			.catch(error => ({ error, url }))
		)
	)
}

export class MarketsMap extends Component {
	constructor(props) {
		super(props)
		this.state = {
			layerMode: LayerModes.reduced,
			zoomToBounds: false,
			style: geoJsonStyle,
			mapsInitialied: false
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.type !== prevProps.type) {
			this.setState({
				type: prevProps.type
			})
			this.state.mapsInitialied && this.handleOnAddLayers();
		}
		if (this.props.feautres !== prevProps.features || this.props.selectedOptions !== prevProps.selectedOptions) {
			this.state.mapsInitialied && this.handleOnAddLayers();
		}

	}


	render() {
		const { layers, zoomToBounds, zoomBounds, style, type } = this.state
		const { config, initOptions } = this.props
		return (
			<div className={type}>
				<MapApi apiKey={config.mapApi} libraries="geometry" >
					<Map initOptions={initOptions} options={{ streetViewControl: false, zoomControlOptions: { position: 1 } }} onInitialized={(d) => { d && this.handleOnAddLayers(); this.setState({ mapsInitialied: true }); }} >
						<FitToBounds fit={zoomToBounds} bounds={zoomBounds} onFitToBounds={(bounds) => this.handleOnFitToBounds()} />
						<Data
							onDataChanged={(f) => this.handleOnDataChanged(f)}
						// onClick={(feature, event) => this.handleOnClick(feature, event)}
						// onMouseOver={(feature, event) => this.handleOnMouseOver(feature, event)}
						// onMouseOut={(feature, event) => this.handleOnMouseOut(feature, event)}
						// onMouseMove={(feature, event) => this.handleOnMouseMove(feature, event)}
						>
							<GeoJson data={layers} options={geoJsonOptions} />
							<DataStyle style={style} />
						</Data>
					</Map>
				</MapApi>
			</div >
		)
	}


	handleMouseEnterLayer(id) {
	}


	handleMouseLeaveLayer(id) {

	}

	handleOnClick(feature, event) {

	}

	handleOnMouseMove(feature, event) {

	}

	handleOnMouseOver(feature, event) {

	}

	handleOnMouseOut(feature, event) {

	}

	handleOnDataChanged(features) {
		const { countryCode } = this.props.preferencesObject
		let zoomBounds
		let zoomToRegion = countryCode => {
			if( countryCode == "USA" || countryCode == "US" ){
				zoomBounds = new window.google.maps.LatLngBounds(
					new window.google.maps.LatLng(usFitBounds.southwest), 
					new window.google.maps.LatLng(usFitBounds.northeast)
				  );
			}else if( countryCode == "CA" || countryCode == "CAN"){
				zoomBounds = new window.google.maps.LatLngBounds(
					new window.google.maps.LatLng(caFitBounds.southwest), 
					new window.google.maps.LatLng(caFitBounds.northeast)
				  );
			}else if( countryCode == 'GB' || countryCode == 'GBR' ){
				zoomBounds = new window.google.maps.LatLngBounds(
					new window.google.maps.LatLng(ukFitBounds.southwest), 
					new window.google.maps.LatLng(ukFitBounds.northeast)
				  );
			}
			let zoomToBounds = true
			this.setState({ zoomToBounds, zoomBounds })
		}

		if (features && features.length > 0) {
			let zoomBounds = getBoundsByFeatures(...features).toJSON()
			let zoomToBounds = features.length > 0
			this.setState({ features, zoomToBounds, zoomBounds })
			
		} else {
			zoomToRegion(countryCode);
		}
	}

	handleOnFitToBounds() {
		this.setState({ zoomToBounds: false })
	}

	handleOnAddLayers() {
		const { selectedOptions, config } = this.props
		//exclude Rapid City
		const selectedOptionsMapped = selectedOptions.map(selectedOption => {
			if (typeof selectedOption == "object") {
				return selectedOption.value
			} else {
				return selectedOption
			}
		})
		const selectedOptionsFiltered = selectedOptionsMapped.filter(selectedOption => {

			if (selectedOption !== 352) {
				return selectedOption;
			}

		})

		if (selectedOptions.length === 0) {
			this.setState({
				layers: undefined
			})
		}

		let marketsGeo = {
			"type": "FeatureCollection",
			"features": []
		}

		const entireMarketListPolys = [...USMarketsPolys, ...CAMarketsPolys, ...UKMarketsPolys]
		selectedOptionsFiltered.forEach(market => {
			let marketData = entireMarketListPolys.filter(o => o.market == market)[0]
			let marketPolys = marketData.polylines.lines
			let latlngs = marketPolys.map(x => x.map(y => window.google.maps.geometry.encoding.decodePath(y)));
			let polygons = latlngs.map(x => x.map(y => y.map(z => [z.lng(), z.lat()])));
			let singleMarketGeo = {
				"type": "Feature",
				"properties": {
					"fillColor": "blue"
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": polygons
				}
			}
			marketsGeo.features.push(singleMarketGeo);
		})

		this.setState({
			layers: marketsGeo
		})

		// const urlsForGeo = selectedOptionsFiltered.map(market => {
		// 	return `${config.shapesServerURL}/shapes/market/${market}/low`
		// });

		// fetchAll(urlsForGeo).then(dataArray => {
		// 	console.log(dataArray);

		// 	if (dataArray.length) {
		// 		dataArray.forEach(marketShape => {
		// 			let latlngs = marketShape.data.lines.map(x => x.map(y => window.google.maps.geometry.encoding.decodePath(y)));
		// 			// console.log(latlngs);
		// 			let polygons = latlngs.map(x => x.map(y => y.map(z => [z.lng(), z.lat()])));
		// 			// console.log(polygons);
		// 			let singleMarketGeo = {
		// 				"type": "Feature",
		// 				"properties": {
		// 					"fillColor": "blue"
		// 				},
		// 				"geometry": {
		// 					"type": "MultiPolygon",
		// 					"coordinates": polygons
		// 				}
		// 			}
		// 			marketsGeo.features.push(singleMarketGeo);
		// 		})
		// 		this.setState({
		// 			layers: marketsGeo
		// 		})
		// 	}
		// });




	}

	handleOnDeleteLayers() {
		this.setState({ layers: undefined })
	}

	handleAddStyle() {
		this.setState({ style: geoJsonStyle })
	}

	handleRemoveStyle() {
		this.setState({ style: undefined })
	}

	consolelog(message, ...args) {
		const { consoleLog } = this.props
		if (consoleLog === true) {
			console.log(message, ...args)
		}
	}
}

export default MarketsMap
