import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Checkbox from "./Checkbox";
import _ from "lodash";
import { RangeInput } from "@costar/csgp-ui-react"
import { Validatable, ValidationMessage } from "../validation/validatable"
import classnames from "classnames"
import { StarRating } from "@costar/suite-common-components"
const customStyles = {
  clearIndicator: (base, state) => {
    return {
      ...base,
      display: "none"
    };
  },
  control: (base, state) => {
    if (state.isFocused) {
      base["boxShadow"] = "0 0 4px 0 #0559B3";
      base["border"] = "1px solid #0559B3";
    } else {
      base["border"] = "1px solid #C5C5C5";
    }

    return {
      ...base,
      borderRadius: 1,
      "&:hover": {
        cursor: "pointer"
      }
    };
  },
  indicatorSeparator: (base, state) => {
    return {
      ...base,
      display: "none"
    };
  },
  menu: (base, state) => {
    return {
      ...base,
      marginTop: 0,
      border: "1px solid #0559B3",
      borderRadius: "0px 0px 3px 3px",
      boxShadow: "0 0 4px 0 #0559B3"
    };
  },
  multiValue: (base, state) => {
    return {
      ...base,
      borderRadius: 10,
      backgroundColor: "#F5F5F5",
      border: "1px solid #EAEAEA"
    };
  },
  multiValueRemove: (base, state) => {
    return {
      ...base,
      "&:hover": {
        backgroundColor: "#F5F5F5"
      }
    };
  },
  option: (base, state) => {
    return {
      ...base,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0559B3",
        color: "#FFF"
      }
    };
  }
};

const customStyles2 = _.clone(customStyles);

customStyles2.menuList = (base, state) => {
  return {
    ...base,
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      webkitAppearance: "none",
      width: "7px"
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgb(0,0,0, 0.6)",
      borderRadius: "6px"
    }
  }
};

class Leftside extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      spaceUseOptionsArray: [],
      selectMarketArray: [],
      salePrice: {}
    };

    this.handleSelectMarkets = this.handleSelectMarkets.bind(this);
    this.handleMarketSelectionChange = this.handleMarketSelectionChange.bind(this);
    this.handleSpaceUseSelectionChange = this.handleSpaceUseSelectionChange.bind(this);
    this.handleSalePriceSelectionChange = this.handleSalePriceSelectionChange.bind(this)
    this.handlePropertyTypeSelectionChange = this.handlePropertyTypeSelectionChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.determineIsRangeInValid = this.determineIsRangeInValid.bind(this)
    this.determineInvalidMessage = this.determineInvalidMessage.bind(this)
    this.handleSpaceRange = this.handleSpaceRange.bind(this);
  }

  componentWillReceiveProps(nextprops) {
    let selectedOption = [];

    const options = this.state.spaceUseOptionsArray;

    _(nextprops.spaceUseArray).forEach(function (val) {
      const foundValue = _.find(options, function (item) {
        const matchVal = val.value ? val.value : val;
        return item.value === matchVal;
      });

      if (typeof foundValue !== "undefined") {
        selectedOption.push(foundValue);
      }
    });

    const spaceUseOptionsArray = nextprops.spaceUseOptionsArray.map(obj => {
      return { value: obj.Id, label: obj.Name }
    })

    this.setState({
      selectedOption: selectedOption.slice(),
      spaceUseOptionsArray,
      spaceRange: nextprops.spaceRange
    });
  }

  handleSelectMarkets() {
    this.props.handleMarketClick();
  }


  determineIsRangeInValid(preferences) {
    if (!preferences || !preferences.min || !preferences.max) {
      return false
    }
    if (preferences.min && preferences.max) {
      return (Number.isInteger(preferences.min) && Number.isInteger(preferences.max) && preferences.min > preferences.max)
    }
    return false
  }

  determineInvalidMessage(min, max) {
    if (min === undefined || max === undefined) {
      return ""
    }
    if (min > max) {
      return "Min must be less than or equal to max."
    }
  }

  handleMarketSelectionChange(market) {
    this.props.updateSelectedMarkets(market);
  }

  handleSpaceUseSelectionChange(selectedOption) {
    this.setState(
      {
        selectedOption
      },
      () => {
        this.props.updateSpaceUseArray(selectedOption);
      }
    );
  }
  handleSalePriceSelectionChange(selectedOption) {
    this.setState(
      {
        selectedOption
      },
      () => {
        this.props.handleSalePricePreferenceChange(selectedOption);
      }
    );
  }

  handlePropertyTypeSelectionChange(propertyTypes) {
    this.props.updatePropertyTypeArray(propertyTypes);
  }

  handleRegionChange(region) {
    this.props.handleRegionSelectionChange(region);
  }

  handleSpaceRange(range) {
    let minEnteredValue = range.minValue;
    let maxEnteredValue = range.maxValue;
    let spaceRange = {
      "min": minEnteredValue,
      "max": maxEnteredValue
    }
    this.setState({ spaceRange: spaceRange }, () => {
      this.props.updateSpaceRange(spaceRange)
    })

  }

  handleTypeChange(type) {
    if(this.props.type !== type){
      this.props.handleTypeChange(type);
    }
  }

  render() {
    const { selectedOption, starRating } = this.state;
    let showSelect = "";
    let marketLength = 0;
    let marketSelectionArray;
    let leaseClass = '';
    let saleClass = '';

    if(this.props.type === 'lease'){
      saleClass = 'disabled';
    } else {
      leaseClass = 'disabled';
    }

    let headerForLeaseForSale = (
      <div className="header-component">
        <div className="header-top">
          <div className="toggle-buttons">
            <button 
              className={leaseClass}
              onClick={() => this.handleTypeChange('lease')}
            >
              Lease
            </button>
            <button 
              className={saleClass}
              onClick={() => this.handleTypeChange('sale')}
            >
              Sale
            </button>
          </div>
        </div>
      </div>
    )

    let saveButton = (
      <div className="header-spacing save-button-wrapper">
        <button
          className={!this.props.saveButtonEnabled ? 'disabled' : ''}
          disabled={!this.props.saveButtonEnabled}
          onClick={this.props.onSave}>
          Save
        </button>
      </div>
    )

    if (this.props.type === 'lease') {
      const min = this.props.spaceRange ? this.props.spaceRange.min : undefined
      const max = this.props.spaceRange ? this.props.spaceRange.max : undefined
      const showError = this.determineIsRangeInValid(this.props.spaceRange)
      showSelect = (
        <>
          <div>
            <div className="space-use">Space Use</div>
            <Select
              isMulti
              options={this.state.spaceUseOptionsArray}
              placeholder="Select"
              styles={customStyles2}
              onChange={this.handleSpaceUseSelectionChange}
              value={selectedOption}
            />
          </div>
          <div className="space-range">
            <div className="space-range-label"><p>Space Range</p></div>
            <RangeInput
              name="spaceRange"
              onChange={(newValue) => { this.handleSpaceRange(newValue) }}
              mask={{
                name: "area"
              }}
              valueField={"spaceRange"}
              minPlaceholder="Min SF"
              maxPlaceholder="Max SF"
              changeMode={1}
              value={{
                min: min,
                max: max
              }}
              minAllowedValue={1}
              maxAllowedValue={999999999}
              className={classnames('col-combo-2', {
                error: showError
              })}
            />
            <div className="hint">
              <ValidationMessage
                message={this.determineInvalidMessage(min, max)}
                show={showError}
              />
            </div>
          </div>
        </>
      );

      marketLength = this.props.forLeaseMarketSelections.length;
      marketSelectionArray = this.props.forLeaseMarketSelections;
    } else {
      const showError = this.determineIsRangeInValid(this.props.salePricePreference)
      showSelect = (
        <>
          <div>
            <div className="property-type">Property Type</div>
            <Select
              isMulti
              options={this.props.propertyTypeOptionsArray}
              placeholder="Select"
              styles={customStyles2}
              onChange={this.handlePropertyTypeSelectionChange}
              value={this.props.propertyTypeArray}
            />
          </div>
          <div className="space-range">
            <div className="space-range-label"><p>Sale Price</p></div>
            <RangeInput
              name="currency"
              onChange={(newValue) => this.handleSalePriceSelectionChange(newValue)}
              mask={{
                name: "currency"
              }}
              valueField={"salePrice"}
              minValue={this.props.salePricePreference.min}
              maxValue={this.props.salePricePreference.max}
              minPlaceholder="Min $"
              maxPlaceholder="Max $"
              changeMode={1}
              className={classnames('col-combo-2', {
                error: showError
              })}
            />
            <div className="hint">
              <ValidationMessage
                message={this.determineInvalidMessage(this.props.salePricePreference.min, this.props.salePricePreference.max)}
                show={showError}
              />
            </div>
            <Checkbox
              className="undisclosed-price-box"
              name="includeUndisclosedSalePrice"
              checked={this.props.includeUndisclosedSalePrice}
              handleCheckboxChange={this.props.handleUndisclosedCheckbox}
              text="Include Undisclosed Sale Price"
            />
          </div>
        </>
      );

      marketLength = this.props.forSaleMarketSelections.length;
      marketSelectionArray = this.props.forSaleMarketSelections;
    }

    let marketSelected = "";

    if (marketLength > 0) {
      marketSelected = (
        <div className="markets-selected" onClick={this.handleSelectMarkets}>
          <div>
            {marketLength} market{marketLength > 1
              ? "s"
              : ""}{" "}
            selected
          </div>
          <div className="pencil-icon" />
        </div>
      );
    }

    const { availableMarketList } = this.props;

    let formattedOptions = [];

    _(marketSelectionArray).forEach(function (val) {
      const match = _.find(availableMarketList, function (obj) {
        const matchValue = val.value ? val.value : val;
        return obj.value === matchValue;
      });

      if (typeof match !== 'undefined') {
        formattedOptions.push(match);
      }
    });

    let regionSelect = "";

    let showRegularContents = "";

    if (this.props.showRegionSelect) {
      regionSelect = (
        <div>
          <div>Select a region</div>
          <Select
            className="region-selection"
            styles={customStyles}
            onChange={this.handleRegionChange}
            options={[
              { value: 'US', label: 'United States' },
              { value: 'CA', label: 'Canada' },
              { value: 'GB', label: 'United Kingdom' }
            ]}
          />
        </div>
      )
    } else {
      showRegularContents = (
        <div>
          <div className="property-location">Property Location</div>
          <div className="select-markets">
            <div onClick={this.handleSelectMarkets}>Select Markets</div>
            {marketSelected}
          </div>

          <Select
            className="market-chipinput"
            isMulti
            options={availableMarketList}
            placeholder="Select"
            styles={customStyles2}
            onChange={this.handleMarketSelectionChange}
            value={formattedOptions}
          />

          {showSelect}
          
          <div>
            <div className="star-rating-wrapper">Star Rating</div>
            <StarRating mode="normal" selected={this.props.starRating} zeroBased={false} onStarRatingChange={ (starsArray) => { 
              //pass stars array
              this.props.updateStarRating(starsArray);
              } } />
          </div>

          <Checkbox
            name="unsubscribeFromLease"
            checked={this.props.unsubscribeFromLease}
            handleCheckboxChange={this.props.handleCheckboxChange}
            text="Unsubscribe from emails about space for lease"
          />
          <Checkbox
            name="unsubscribeFromSale"
            checked={this.props.unsubscribeFromSale}
            handleCheckboxChange={this.props.handleCheckboxChange}
            text="Unsubscribe from emails about property for sale"
          />
          <Checkbox
            name="unsubscribeFromAll"
            checked={this.props.unsubscribeFromAll}
            handleCheckboxChange={this.props.handleCheckboxChange}
            text="Unsubscribe from all email campaigns"
          />
        </div>
      )
    }

    return (
      <div className="leftside-component">
        {headerForLeaseForSale}
        <div className="leftside-content">
          {regionSelect}

          {showRegularContents}

          {saveButton}
        </div>
      </div>
    );
  }
}

Leftside.propTypes = {
  unsubscribeFromLease: PropTypes.bool.isRequired,
  unsubscribeFromSale: PropTypes.bool.isRequired,
  unsubscribeFromAll: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleMarketClick: PropTypes.func.isRequired,
  handleRegionSelectionChange: PropTypes.func.isRequired,
  updateSelectedMarkets: PropTypes.func.isRequired,
  updateSpaceUseArray: PropTypes.func.isRequired,
  propertyTypeOptionsArray: PropTypes.array.isRequired,
  propertyTypeArray: PropTypes.array.isRequired,
  updatePropertyTypeArray: PropTypes.func.isRequired,
  spaceUseArray: PropTypes.array.isRequired,
  spaceUseOptionsArray: PropTypes.array.isRequired,
  forLeaseMarketSelections: PropTypes.array,
  forSaleMarketSelections: PropTypes.array,
  type: PropTypes.string.isRequired,
  showRegionSelect: PropTypes.bool.isRequired,
  spaceRange: PropTypes.object,
  updateSpaceRange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func,
  saveButtonEnabled: PropTypes.bool.isRequired
};

export default Leftside;
