import React from "react";
import PropTypes from "prop-types";
import {findIndex, includes } from "lodash";

class MarketSelection extends React.Component {
  constructor(props) {
    super(props);

    this._onChange = this._onChange.bind(this);
    this._handleRightChange = this._handleRightChange.bind(this);
    this._handleLeftChange = this._handleLeftChange.bind(this);
    this._handleAddAll = this._handleAddAll.bind(this);
    this._handleClear = this._handleClear.bind(this);
    this._handleSingleRightArrow = this._handleSingleRightArrow.bind(this);
    this._handleSingleLeftArrow = this._handleSingleLeftArrow.bind(this);
    this._onDimiss = this._onDimiss.bind(this);
    this._onSelected = this._onSelected.bind(this);
    this._handleDoubleArrowAll = this._handleDoubleArrowAll.bind(this);

    this._handleLeftDoubleClick = this._handleLeftDoubleClick.bind(this);
    this._handleRightDoubleClick = this._handleRightDoubleClick.bind(this);

    this.handleResizing = this.handleResizing.bind(this);

    const { selectedOptions } = this.props;

    Object.assign(this, {
      state: {
        searchValue: "",
        availableHighlight: [],
        selectedHighlight: [],
        selectedOptions
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizing);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizing);
  }

  handleResizing() {
    if(window.innerWidth < 1010){
      this._onDimiss();
    }
  }

  _onChange(e) {
    this.setState({
      searchValue: e.target.value,
      availableHighlight: []
    });
  }

  _handleRightChange(e) {
    let options = e.target.options;
    let slctd = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        slctd.push(parseInt(options[i].value, 10));
      }
    }
    this.setState({ selectedHighlight: slctd });
  }

  _handleLeftChange(e) {
    let options = e.target.options;
    let slctd = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        slctd.push(parseInt(options[i].value, 10));
      }
    }
    this.setState({ availableHighlight: slctd });
  }

  _handleAddAll() {
    let { allOptions } = this.props;

    let newArr = [];
    allOptions.forEach(item => {
      newArr.push(item.value);
    });
    this.setState({
      selectedOptions: newArr,
      availableHighlight: [],
      selectedHighlight: []
    });
  }

  _handleDoubleArrowAll() {
    const { allOptions } = this.props;

    let availableMarket = function(arr1, arr2, searchVal) {
      let newArr = [];

      arr1.forEach(item => {
        if (findIndex( arr2, function(itm) { return item.value === itm; } ) === -1 ) {
          if (searchVal && searchVal !== "") {
            if ( includes(item.label.toLowerCase(), searchVal.toLowerCase())) {
              newArr.push(item);
            }
          } else {
            newArr.push(item);
          }
        }
      });
      return newArr;
    };

    let availMrkt = availableMarket(
      allOptions,
      this.state.selectedOptions,
      this.state.searchValue
    );

    if (availMrkt && Array.isArray(availMrkt)) {
      let newArr = this.state.selectedOptions ? this.state.selectedOptions : [];
      availMrkt.forEach(item => {
        if ( findIndex( this.state.selectedOptions, function(itm) { return item.value === itm;} ) === -1  ) {
            newArr.push(item.value);
        }
      });

      this.setState({
        selectedOptions: newArr,
        availableHighlight: [],
        selectedHighlight: []
      });
    }
  }

  _handleClear() {
    this.setState({
      selectedOptions: [],
      availableHighlight: [],
      selectedHighlight: []
    });
  }

  _handleSingleRightArrow() {
    if (
      this.state.availableHighlight &&
      Array.isArray(this.state.availableHighlight)
    ) {
      let newArr = this.state.selectedOptions ? this.state.selectedOptions : [];
      this.state.availableHighlight.forEach(item => {
        if ( findIndex( this.state.selectedOptions, function(itm) { return item === itm;} ) === -1) {
          newArr.push(item);
        }
      });

      this.setState({
        selectedOptions: newArr,
        availableHighlight: [],
        selectedHighlight: []
      });
    }
  }

  _handleSingleLeftArrow() {
    if (
      this.state.selectedHighlight &&
      Array.isArray(this.state.selectedHighlight)
    ) {
      let newArr = [];
      this.state.selectedOptions.forEach(item => {
        if ( findIndex( this.state.selectedHighlight, function(itm) { return item === itm;} ) === -1) {  
          newArr.push(item);
        }
      });

      this.setState({
        selectedOptions: newArr,
        availableHighlight: [],
        selectedHighlight: []
      });
    }
  }

  _onDimiss() {
    this.props.onDismiss();
  }

  _onSelected() {
    this.props.onSelected(this.state.selectedOptions);
  }


  _handleLeftDoubleClick( e) {
    if (e && e.target && e.target.value) {

      let intValue = parseInt( e.target.value, 10);
      let newArr = this.state.selectedOptions
        ? this.state.selectedOptions
        : [];

      if ( findIndex(this.state.selectedOptions, function( itm ) {return intValue === itm;} ) === -1 ) {  
        newArr.push(intValue);
      }

      this.setState({
        selectedOptions: newArr,
        availableHighlight: [],
        selectedHighlight: []
      });
    }
  }

  _handleRightDoubleClick( e) {

    if (e && e.target && e.target.value) {

      let intValue = parseInt( e.target.value, 10);
      let newArr = [];

      this.state.selectedOptions.forEach(item => {
        if (item !== intValue) {
          newArr.push(item);
        }
      });

      this.setState({
        selectedOptions: newArr,
        availableHighlight: [],
        selectedHighlight: []
      });
    }
  }


  render() {
    const { allOptions } = this.props;

    const availableMarket = function(arr1, arr2, searchVal) {
      let newArr = [];

      arr1.forEach(item => {
        if ( findIndex( arr2,  function(itm) { 
          const matchVal = itm.value ? itm.value : itm;
          return item.value === matchVal;
        } ) === -1) {  
          if (searchVal && searchVal !== "") {
            if ( includes( item.label.toLowerCase(), searchVal.toLowerCase())) {
              newArr.push(item);
            }
          } else {
            newArr.push(item);
          }
        }
      });
      return newArr;
    };

    const selectedMarket = function(arr1, arr2) {
      let newArr = [];
      arr1.forEach(item => {
        if ( findIndex( arr2,  function(itm) {
          const matchVal = itm.value ? itm.value : itm;
          return item.value === matchVal;
        } ) >= 0) {
          newArr.push(item);
        }
      });
      return newArr;
    };

    const availMrkt = availableMarket(
      allOptions,
      this.state.selectedOptions,
      this.state.searchValue
    );

    const slctlMrkt = selectedMarket(allOptions, this.state.selectedOptions);

    const name = "searchinput";
    const primPlaceholder = "Search";

    return (
      <div className="market">
        <div className="market-content">
          <div className="market-grid">
            <div className="market-col">
              <div className="market-col-container-table">
                <div className="title">Available Market Areas</div>
                <div className="market-spacer " />
                <div className="search">
                  <div key={name} className="search-input">
                    <input
                      type="text"
                      id={name}
                      name={name}
                      placeholder={primPlaceholder}
                      value={this.state.searchValue}
                      onChange={this._onChange}
                    />
                    <div className="search-icon" ></div>
                  </div>
                </div>
                <div className="market-spacer " />
                <div className="market-selector">
                  <div className="market-selector-container">
                    <select
                      className="market-selector-input"
                      multiple={true}
                      value={this.state.availableHighlight}
                      onChange={this._handleLeftChange}
                      onDoubleClick={this._handleLeftDoubleClick}
                    >
                      {availMrkt.map((item, index) => {
                        return (
                          <option
                            key={`available-optn-${index}`}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    { availMrkt && availMrkt.length ===  0 && 
                    this.state.searchValue && this.state.searchValue.length > 0 &&
                    (<div className="market-selector-message">
                      <span>No Results Found</span>
                    </div>)
                    }
                  </div>
                </div>
                <div className="market-spacer " />
              </div>
            </div>
            <div className="market-col-middle">
              <div className="market-col-container-table">
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="market-button double-right"
                    onClick={this._handleDoubleArrowAll}
                  />
                  <button
                    type="button"
                    className="market-button single-right"
                    onClick={this._handleSingleRightArrow}
                  />
                  <button
                    type="button"
                    className="market-button single-left"
                    onClick={this._handleSingleLeftArrow}
                  />
                  <button
                    type="button"
                    className="market-button double-left"
                    onClick={this._handleClear}
                  />
                </div>
              </div>
            </div>
            <div className="market-col">
              <div className="market-col-container-table">
                <div className="title">Selected Market Areas</div>
                <div className="market-spacer " />
                <div className="add-clear">
                  <div className="add-clear-col padding-right">
                    <button
                      type="button"
                      className="market-button-add-clear"
                      onClick={this._handleAddAll}
                    >
                      Add All
                    </button>
                  </div>
                  <div className="add-clear-col padding-left">
                    <button
                      type="button"
                      className="market-button-add-clear"
                      onClick={this._handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="market-spacer " />

                <div className="market-selector">
                  <div className="market-selector-container">
                    <select
                      className="market-selector-input"
                      multiple={true}
                      value={this.state.selectedHighlight}
                      onChange={this._handleRightChange}
                      onDoubleClick={this._handleRightDoubleClick}
                    >
                      {slctlMrkt.map((item, index) => {
                        return (
                          <option
                            key={`selection-optn-${index}`}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    { slctlMrkt && slctlMrkt.length ===  0 &&
                    (<div className="market-selector-message">
                      <span>Select the Market Areas from the list on the left by double-clicking. <br/> <br/> or <br/> <br/> Single click to highlight and use the arrow buttons to add to the list of Selected Market Areas.</span>
                    </div>)
                    }

                  </div>
                </div>
                <div className="market-spacer " />
              </div>
            </div>
          </div>
        </div>

        <div className="market-footer">
          <button
            type="button"
            className="market-action-button highlighted pull-right"
            onClick={this._onSelected}
          >
            Apply
          </button>
          <button
            type="button"
            className="market-action-button pull-right"
            onClick={this._onDimiss}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

MarketSelection.propTypes = {
  allOptions: PropTypes.array,
  selectedOptions: PropTypes.array,
  onDismiss: PropTypes.func,
  onSelected: PropTypes.func,
  formatMessage: PropTypes.func
};

export default MarketSelection;
