import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import AutoNumeric from "autonumeric";
import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { fetchConfig } from './requests';
//making AutoNumeric globally available:
window.AutoNumeric = AutoNumeric;

// import registerServiceWorker from './registerServiceWorker';

// Wrapping the render of the app so that we can pass in config that is fetched from server
window.RenderApp = (configUrl) => {
  fetchConfig(configUrl).then((config) => {
    render((
      <BrowserRouter>
        <App _config={config}/>
      </BrowserRouter>
    ),
      document.getElementById('root')
    );
  })
}
// registerServiceWorker();
