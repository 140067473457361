
const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json"
};

export const preferencesGetRequest = (config, email) => {
  const encodedEmailAddress = btoa(email);
  return fetch(`${config.baseURL}/preferences/${encodedEmailAddress}`, {
    method: 'get',
    headers,
    cache: "no-cache"
  })
    .then(response => response.json());
}

export const lookupValuesRequest = (config, region, fullLocale) => {
  return fetch(`${config.baseURL}/lookup-values/${region}/${fullLocale}`, {
    method: 'get',
    headers,
    cache: "no-cache"
  })
    .then(response => response.json());
}

export const savePreferences = (config, emailAddress, dynamoRequest) => {
  const encodedEmailAddress = btoa(emailAddress);
  return fetch(`${config.baseURL}/preferences/${encodedEmailAddress}`, {
      method: "post",
      resolveWithFullResponse: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dynamoRequest),
    })
}

export const fetchConfig = (configUrl) => {
  return fetch(configUrl, {
    method: 'get',
    headers,
    cache: "no-cache"
  })
    .then(response => response.json());
}