import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Main from './containers/Main';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: this.props._config
    }
  }
  render() {
    return (
      <div className="app-container">
        <Route path="/" render={(props)=><Main {...props} _config={this.state.config}/>}/>
      </div>
    );
  }
}

export default App;