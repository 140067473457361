import React from 'react';

class Header extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className="header-component">
        <div className="header-top">
          <div className="header-component-heading">Listing Email Preferences</div>
        </div>
        <div className="header-shadow"></div>
      </div>
    );
  }

}

export default Header;