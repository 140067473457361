// tslint:disable
import React from 'react';
import MarketSelection from './MarketSelection';
import MarketsMap from './Map';

const countryCodesLatsLongs = {
  USA: { coordinates: { lat: 32.7168216845144, lng: -117.15682983398439 }, zoom: 4 },
  CAN: { coordinates: { lat: 59.985358, lng: -111.180755 }, zoom: 4 },
  GBR: { coordinates: { lat: 55.081118, lng: -2.271425 }, zoom: 5 }
}

const countryCodesLatsLongsAliases = {
  US: countryCodesLatsLongs.USA,
  CA: countryCodesLatsLongs.CAN,
  GB: countryCodesLatsLongs.GBR
}

const allPossibleRegionAliases = Object.assign({}, countryCodesLatsLongs, countryCodesLatsLongsAliases);


const Rightside = (props) => {
  const selectedOptions = props.type === 'lease' ? props.forLeaseMarketSelections : props.forSaleMarketSelections;

  const marketSelectionProps = {
    allOptions: props.availableMarketList,
    selectedOptions,
    onDismiss: props.onDismiss,
    onSelected: props.onSelected,
    type: props.type,
    config: props.config,
  };

  let outputMarket = '',
    preview = '',
    previewClassNames = 'rightside-component';

  if (props.showMarketModal) {
    outputMarket = (
      <div className="market-modal" >
        <MarketSelection {...marketSelectionProps} />
      </div>
    );
  }

  if (props.preferencesObject && props.preferencesObject.countryCode) {
    previewClassNames = `${previewClassNames} no-bg`
    let countryCodeInfo = allPossibleRegionAliases[props.preferencesObject.countryCode]
    Object.assign(marketSelectionProps, {
      initOptions: { center: countryCodeInfo.coordinates, zoom: countryCodeInfo.zoom }
    }, {preferencesObject: props.preferencesObject})
    preview = (
      <div className="map-view">
        {outputMarket}
        <MarketsMap {...marketSelectionProps} />
      </div>
    );
  } else {
    preview = (
      <div>
        {outputMarket}
      </div>
    )
  }

  return (
    <div className={previewClassNames}>
      {preview}
    </div>
  );
}


export default Rightside;